<template>
  <div class="container">
    <div class="content">
      <div class="counts-info">
        全部含"
        <span>{{ word }}</span>"
        <span>共{{ totlePage }}条结果</span>
      </div>
      <div class="list">
        <div class="item" v-for="item in list" :key="item.QID">
          <div class="title" @click="details(item.QID)" v-html="item.QTitle"></div>
          <div v-if="item.QContent">
            问:
            <span
              v-html="item.QContent.length>=100?(item.QContent.substring(0, 100)+'...'):item.QContent"
            ></span>
            <!-- <span v-if="item.QContent == undefined || item.QContent.length >= 100">
              {{item.QContent == undefined? "": item.QContent.substring(0, 100) + "..."}}
            </span>
            <span v-if="item.QContent == undefined ||item.QContent.length < 100">{{ item.QContent }}</span>-->
          </div>
          <!-- <div v-if="item.answer" > -->
          <!-- <div v-for="(items,index) in ref[item.id]" :key="index">
                        答:<span>{{items.answer}}</span>
          </div>-->
          <div v-if="item.ADetail">
            答:
            <span
              v-html="item.ADetail.length>=100?(item.ADetail.substring(0, 100)+'...'):item.ADetail"
            ></span>
            <!-- <span v-if="item.ADetail == undefined || item.ADetail.length >= 100">
              {{
              item.ADetail == undefined
              ? ""
              : item.ADetail.substring(0, 100) + "..."
              }}
            </span>
            <span v-if="item.ADetail == undefined || item.ADetail.length < 100">
              {{
              item.ADetail
              }}
            </span>-->
          </div>
          <div class="keyword-list">
            <i class="keyword-icon"></i>
            <span
              @click="keyWordSearch(word)"
              class="keyword color-8590A6 title12"
              v-for="(word, index) in JSON.parse(item.keyword)"
              :key="index"
            >{{ word }}</span>
          </div>
          <div class="ansers">
            <!-- 时间 -->
            <span style="width:25%;text-align:left">{{ acquireTime(item.QCreateTime) }}</span>
            <!-- 回答者 -->
            <span style="width:25%;text-align:left" v-if="item.ShowedUser != null">
              发布者：
              <span v-if="item.ShowedUser.length > 9">
                {{
                item.ShowedUser.substring(0, 9) + "..."
                }}
              </span>
              <span v-if="item.ShowedUser.length <= 9">{{ item.ShowedUser }}</span>
            </span>
            <span style="width:25%;text-align:left" v-if="item.ShowedUser == null">发布者：无</span>
            <!-- 多少个回答 -->
            <span style="width:25%;text-align:left">{{ item.AnswerNum }}个回答</span>
            <!-- 收藏数 -->
            <span style="width:24%;text-align:left">
              <span class="collect2-icon"></span>
              {{ item.CollectNum }}
            </span>
          </div>
        </div>
      </div>
      <!-- 分页 -->
      <div class="page-nav">
        <el-pagination
          background
          layout="prev, pager, next"
          :hide-on-single-page="hidePageNav"
          :total="totlePage"
          :page-size="pageSize"
          @prev-click="prevPage"
          @next-click="nextPage"
          @current-change="currentPage"
        ></el-pagination>
      </div>
      <div v-if="list.length==0"  class="zhanshi">暂时未检索到相关问题</div>
    </div>
  </div>
</template>

<script>
import { DELETE, QUERY, UPDATES } from "@/services/dao.js";
import { get, post } from "@/services/http.js";
import { dataFormat, showTimeFormat } from "@/js/common/index.js";
export default {
  data() {
    return {
      word: "",
      pageNum: 1, //页数
      pageSize: 10, //每页条数
      totlePage: 0, //总条数
      hidePageNav: true,
      ref: [],
      list: []
    };
  },
  watch: {
    $route(to, from) {
      //监听路由是否变化

      if (to.query.word != from.query.word) {
        this.word = this.$route.query.word;
        this.init(); //重新加载数据
      }
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.searchInfo(this.pageNum);
    },
    //跳转
    async details(id) {
      console.log("id:" + id);
      let info = await QUERY(
        "post",
        "",
        "  WDNewQuestion(where: {id: {_eq: " + id + "}}) { scanNum  }"
      );
      console.log("热度值" + info.data.WDNewQuestion[0].scanNum);
      let anser = Number(info.data.WDNewQuestion[0].scanNum);
      anser += 1;
      console.log("热度值2:" + anser);
      let dataed = await UPDATES(
        "post",
        "",
        "   update_WdQuestion(where: {id: {_eq: " +
          id +
          "}}, _set: {scanNum: " +
          anser +
          "}) { affected_rows  }"
      );
      if (dataed.data.update_WdQuestion.affected_rows > 0) {
        this.$router.push({
          path: "/writeanswer",
          query: {
            id: id
          }
        });
      }
    },
    //前一页
    prevPage(val) {
      this.searchInfo(val);
    },
    //下一页
    nextPage(val) {
      this.searchInfo(val);
    },
    //当前页
    currentPage(val) {
      console.log(val);
      // this.pageNum = val - 1;
      this.searchInfo(val);
    },
    searchInfo( offset) {
      this.list = [];
      const formData = new FormData();
      formData.append("pageIndex", offset);
      formData.append("pageSize", this.pageSize);
      formData.append("keyword", this.$route.query.word);
      post("/common/zhinengQuery", formData).then(res => {
        console.log(res.content);
        for (let item of res.content) {
          this.list.push({
            QID: item.id,
            QTitle: item.questionTitle,
            QContent: item.questionContent,
            // ADetail:
            QCreateTime: item.createTime,
            CollectNum: item.collectNum,
            AnswerNum: item.answerNum,
            keyword: item.keyword,
            ShowedUser:item.userName
          });
        }
         this.totlePage=res.totalCount
      });
      console.log(this.list);
    },
    keyWordSearch(word) {
      ////标签搜索
      this.$router.push({
        path: "/automatic",
        query: {
          word: word
        }
      });
    },
    // searchInfo(limit, offset){
    //   let query = this.$route.query.word
    //   this.word = query
    //   get('/common/zhinengQuery',{limit,offset,query}).then(res=>{
    //     let getList = []
    //     let list = [
    //       ...res.data.AnswersSearch.AList_Keywords,
    //       ...res.data.AnswersSearch.AList_Users,
    //       ...res.data.QuestionsSearch.QList_Keywords,
    //       ...res.data.QuestionsSearch.QList_Users,
    //     ]
    //     for(let item of list){
    //       let reger1 = new RegExp(query, "gm");
    //       if(getList.length<=0){
    //         if(item.QTitle)item.QTitle = item.QTitle.replace(reger1,'<font color="red">' +query + "</font>")
    //         if(item.ADetail)item.ADetail = item.ADetail.replace(reger1,'<font color="red">' +query + "</font>")
    //         if(item.QContent)item.QContent = item.QContent.replace(reger1,'<font color="red">' +query + "</font>")
    //         getList.push(item)
    //       }else{
    //         let boo = true
    //         for(let obj of getList){
    //           if(obj.QID==item.QID){
    //             boo = false
    //             break
    //           }
    //         }
    //         if(boo){
    //           if(item.QTitle)item.QTitle = item.QTitle.replace(reger1,'<font color="red">' +query + "</font>")
    //           if(item.ADetail)item.ADetail = item.ADetail.replace(reger1,'<font color="red">' +query + "</font>")
    //           if(item.QContent)item.QContent = item.QContent.replace(reger1,'<font color="red">' +query + "</font>")
    //           getList.push(item)
    //         }
    //       }

    //     }
    //     this.list = getList
    //   //  console.log(getList);

    //   })

    // },

    //获得时间
    acquireTime(time) {
      let date = new Date(time);
      let Y = date.getFullYear();
      let M = date.getMonth() + 1;
      let D = date.getDate();
      let h = date.getHours();
      let m = date.getMinutes();
      let s = date.getSeconds();
      return Y + "-" + M + "-" + D + " " + h + ":" + m + ":" + s;
    }

    // async searchInfo1(limit, offset) {
    //   this.list = [];
    //   //alert(this.word)
    //   this.word = this.$route.query.word;
    //   let a = null;
    //   let info = await QUERY(
    //     "post",
    //     "",
    //     'WDQuestion( where: {questionTitle: {_like: "%' +
    //       this.word +
    //       '%"}},limit: ' +
    //       limit +
    //       ", offset: " +
    //       offset +
    //       ') { id  questionTitle  createTime answerNum   QContent  } WDQuestion_aggregate(where: {questionTitle: {_like: "%' +
    //       this.word +
    //       '%"}}) {     aggregate {       count     }   }'
    //   );
    //   let info_1 = await QUERY(
    //     "post",
    //     "",
    //     'WDQuestion( where: {QContent: {_like: "%' +
    //       this.$store.state.userinfo.firstLable +
    //       '%"}},limit: ' +
    //       limit +
    //       ", offset: " +
    //       offset +
    //       ') { id  questionTitle  createTime answerNum   QContent  } WDQuestion_aggregate(where: {QContent: {_like: "%' +
    //       this.$store.state.userinfo.firstLable +
    //       '%"}}) {     aggregate {       count     }   }'
    //   );
    //   let info_2 = await QUERY(
    //     "post",
    //     "",
    //     'WDQuestion( where: {QContent: {_like: "%' +
    //       this.$store.state.userinfo.secondLable +
    //       '%"}},limit: ' +
    //       limit +
    //       ", offset: " +
    //       offset +
    //       ') { id  questionTitle  createTime answerNum   QContent  } WDQuestion_aggregate(where: {QContent: {_like: "%' +
    //       this.$store.state.userinfo.secondLable +
    //       '%"}}) {     aggregate {       count     }   }'
    //   );
    //   let info_3 = await QUERY(
    //     "post",
    //     "",
    //     'WDQuestion( where: {QContent: {_like: "%' +
    //       this.$store.state.userinfo.thirdLable +
    //       '%"}},limit: ' +
    //       limit +
    //       ", offset: " +
    //       offset +
    //       ') { id  questionTitle  createTime answerNum   QContent  } WDQuestion_aggregate(where: {QContent: {_like: "%' +
    //       this.$store.state.userinfo.thirdLable +
    //       '%"}}) {     aggregate {       count     }   }'
    //   );
    //   info.data.WDQuestion = info.data.WDQuestion.concat(
    //     info_1.data.WDQuestion.concat(
    //       info_2.data.WDQuestion.concat(info_3.data.WDQuestion)
    //     )
    //   );
    //   console.log("WDQuestion", info.data.WDQuestion);
    //   var temp = {}; //用于name判断重复
    //   var result = []; //最后的新数组
    //   let strinf=this.word;
    //   let reger1 = new RegExp(strinf, "gm");
    //   info.data.WDQuestion.map(function(item, index) {
    //     if (!temp[item.id]) {
    //       (item.questionTitle = item.questionTitle.replace(
    //         reger1,
    //         '<font color="red">' +strinf + "</font>"
    //       )),
    //         result.push(item);
    //       temp[item.id] = true;
    //     }
    //   });
    //   console.log(result);
    //   info.data.WDQuestion = result;
    //   for (let i = 0; i < info.data.WDQuestion.length; i++) {
    //     a = info.data.WDQuestion[i];
    //     a.createTime = showTimeFormat(a.createTime);
    //     ///收藏数
    //     let datas = await QUERY(
    //       "post",
    //       "",
    //       " WDCollect_aggregate(where: {question: {_eq: " +
    //         info.data.WDQuestion[i].id +
    //         "}}) {     aggregate {       count     }   }"
    //     );
    //     // for(let e = 0; e < datas.data.WDCollect_aggregate.count; e++)
    //     // {
    //     //alert(datas.data.WDCollect_aggregate.aggregate.count)
    //     a.collection = datas.data.WDCollect_aggregate.aggregate.count; //收藏数
    //     // }
    //     let data = await QUERY(
    //       "post",
    //       "",
    //       "  WDUserQuestionAnswerRef(where: {question: {_eq: " +
    //         info.data.WDQuestion[i].id +
    //         "}}, distinct_on: question) {  answer  name}"
    //     );
    //     for (let j = 0; j < data.data.WDUserQuestionAnswerRef.length; j++) {
    //       (a.answer = data.data.WDUserQuestionAnswerRef[j].answer), //回答内容
    //         (a.name = data.data.WDUserQuestionAnswerRef[j].name); //回答者姓名
    //     }
    //     //alert(JSON.stringify(data.data.WDAnswerRef[i].answer) )
    //     this.list.push(a);
    //     // this.list.splice(0,this.list.length);
    //     // for(let j = 0; j < data.data.WDAnswerRef.length; j++){
    //     //  this.list.push({
    //     //       id:info.data.WDQuestion[i].id,
    //     //       questionTitle:info.data.WDQuestion[i].questionTitle,
    //     //       QContent:info.data.WDQuestion[i].QContent,
    //     //       questionAnswer:data.data.WDAnswerRef.answer
    //     //   });
    //     // }
    //     //   info.data.WDQuestion.forEach(element => {
    //     //       this.list.push({
    //     //           id:element.id,
    //     //           questionTitle:element.questionTitle,
    //     //           QContent:element.QContent,
    //     //         //   questionAnswer:"首先了解一下自己是怎样问老师的？首先了解一下自己是怎样问老师的？ 首先了解一下自己是怎样问老师的？ 首先了解一下自己是怎样问老师的？ 首先了解一下自己是怎样问老师的？ 首先了解一下自己是怎样问老师的？ 首先了解一下自己是怎样问老师的？ 首先了解一下自己是怎样问老师的？ 首先了解一下自己是怎样问老师的？ 首先了解一下自己是怎样问老师的？ "
    //     //       });
    //     //     //alert(element.id)

    //     // } );
    //     // for(let i = 0; i < info.data.WDQuestion.length; i++)
    //     // {

    //     //     data=  await  QUERY("post","",'  WDAnswerRef(where: {answers: {_eq: '+this.list[i].id+'}}, limit: 1) {    answer         }');

    //     // }
    //     // this.ref.push(data.data.WDAnswerRef)
    //     //    console.log(this.ref)
    //     if (this.word == "") {
    //       this.totlePage = info.data.WDQuestion_aggregate.aggregate.count;
    //     } else {
    //       this.totlePage = result.length;
    //     }
    //   }
    //   // console.log(JSON.stringify( this.list))
    // }
  }
};
</script>

<style scoped>
@import url(~@/styles/page/index.css);
.content {
  width: 887px;
  background-color: #ffffff;
  margin: 50px auto;
  padding: 30px 50px;
}
.ansers {
  font-size: 13px;
  font-family: Microsoft YaHei;
  font-weight: 100;
  color: #8590a6;
  line-height: 30px;
  /* border: 1px solid red; */
}
.ansers > span {
  /* margin-right: 15%; */
  /* border: 1px dashed darkblue; */
  display: inline-block;
  color: #8590a6;
}

.counts-info {
  font-size: 14px;
  font-weight: 300;
  color: rgba(160, 160, 160, 1);
  border-bottom: 1px solid rgba(160, 160, 160, 1);
  padding-bottom: 10px;
  font-size: 14px;
}
.item {
  padding: 20px 0;
  border-bottom: 1px dashed rgba(160, 160, 160, 1);
  line-height: 25px;
}
.item > div {
  /* border: 1px solid red; */
  width: 100%;
  font-size: 13px;
}
.item .title {
  width: 100%;
  height: 20px;
  line-height: 20px;
  padding-bottom: 15px;
  font-size: 15px;
  cursor: pointer;
  color: #0084ff;
  font-weight: bold;
  font-family: MicrosoftYaHei;
}
.collect2-icon {
  width: 15px;
  height: 15px;
  display: inline-block;
  background-image: url("../../../assets/icon/Collection.png");
  background-size: 15px 15px;
  background-repeat: no-repeat;
  /* border: 1px solid red; */
  position: relative;
  /* top: 5px; */
  left: 5px;
  top: 2px;
  margin-right: 5px;
}
.page-nav {
  width: 100%;
  /* border: 1px solid red; */
}
.keyword-list {
  /* background-color: springgreen; */
  height: 40px;
  line-height: 40px;
}
.keyword {
  border: 1px solid rgba(181, 181, 181, 1);
  border-radius: 13px;
  font-size: 12px;
  padding: 5px;
}
.keyword:not(:first-of-type) {
  margin-left: 5px;
}
.title12 {
  white-space: nowrap;
  text-overflow: ellipsis;
}
.title12:hover {
  cursor: pointer;
  background-color: #fd6e05;
  color: white;
  border: 1px solid #fd6e05;
}
.color-8590A6 {
  font-size: 12px;
  color: #8590a6;
}
.zhanshi{
  color: #8590a6;
  font-size: 15px;
  width: 100%;
  text-align: center;
  height: 50px;
  line-height: 50px;
}
</style>
